/*
  speedyspel.se
  speedybet.com
  speedycasino.com
*/

:root {
  --app-color-brand: #213E51;
  --app-color-accent: #213E51;

  --app-color-off-white: #fff;

  --app-background: #F0F6FA;

  --app-header-height: 56px;
  --app-header-background: #213E51;

  --app-color-neutral-white: #fff;
  --app-color-neutral: #ADB4B5;
  --app-color-neutral-dark: #8b939e;
  --app-color-neutral-darker: #7D838B;

  --app-color-shadow-line: hsla(189, 9%, 15%, 0.15);
  --app-shadow-low: 0 1px 4px var(--app-color-shadow-line);
  --app-shadow-mid: 0 2px 4px var(--app-color-shadow-line);
  --app-shadow-high: 0 5px 10px var(--app-color-shadow-line);  
}

@font-face {
  font-family: headlines;
  font-display: swap;
  src: url("../fonts/gilroy/gilroy-extrabold.woff2") format("woff2"),
    url("../fonts/gilroy/gilroy-extrabold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  text-rendering: optimizeLegibility;
}

body {
  background: var(--app-background);
}

body,
input,
textarea,
select,
button {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1em;
  line-height: 1.5;
  color: var(--app-color-off-white);
}

h1,
h2,
h3 {
  font-family: headlines, Arial, Helvetica, sans-serif;
  letter-spacing: -0.03em;
  line-height: 1.1;
  color: var(--app-color-brand) !important;
  text-transform: uppercase;
}

section a {
  color: var(--app-color-brand) !important;
}

.app-locale-selector button svg {
  fill: var(--app-color-off-white);
}

.app-locale-selector nav {
  border-color: var(--app-color-accent) !important;
}

.app-locale-selector nav::before {
  border-bottom-color: var(--app-color-accent) !important;
}

.app-logo {
  margin-left: 16px !important;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left center;
  display:block;
  width: 158px;
  height: 100%;
}